
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

import { useAuth } from 'src/composables/auth';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		//Dashboard routes


//jdwselasa routes
		{
			path: '/jdwselasa/:fieldName?/:fieldValue?',
			name: 'jdwselasalist',
			component: () => import('pages/jdwselasa/list.vue'),
			props: route => passRouteToProps(route)
		},

//jdwsenin routes
		{
			path: '/jdwsenin/:fieldName?/:fieldValue?',
			name: 'jdwseninlist',
			component: () => import('pages/jdwsenin/list.vue'),
			props: route => passRouteToProps(route)
		},

//leger routes
		{
			path: '/leger/:fieldName?/:fieldValue?',
			name: 'legerlist',
			component: () => import('pages/leger/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/leger/view/:id', 
			name: 'legerview', 
			component: () => import('pages/leger/view.vue'), 
			props: true 
		},
		{ 
			path: '/leger/add', 
			name: 'legeradd', 
			component: () => import('pages/leger/add.vue'), 
			props: true 
		},
		{ 
			path: '/leger/edit/:id', 
			name: 'legeredit', 
			component: () => import('pages/leger/edit.vue'), 
			props: true 
		},

//permissions routes
		{
			path: '/permissions/:fieldName?/:fieldValue?',
			name: 'permissionslist',
			component: () => import('pages/permissions/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/permissions/view/:id', 
			name: 'permissionsview', 
			component: () => import('pages/permissions/view.vue'), 
			props: true 
		},
		{ 
			path: '/permissions/add', 
			name: 'permissionsadd', 
			component: () => import('pages/permissions/add.vue'), 
			props: true 
		},
		{ 
			path: '/permissions/edit/:id', 
			name: 'permissionsedit', 
			component: () => import('pages/permissions/edit.vue'), 
			props: true 
		},

//refabsensiswa routes
		{
			path: '/refabsensiswa/:fieldName?/:fieldValue?',
			name: 'refabsensiswalist',
			component: () => import('pages/refabsensiswa/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refabsensiswa/view/:id', 
			name: 'refabsensiswaview', 
			component: () => import('pages/refabsensiswa/view.vue'), 
			props: true 
		},
		{ 
			path: '/refabsensiswa/add', 
			name: 'refabsensiswaadd', 
			component: () => import('pages/refabsensiswa/add.vue'), 
			props: true 
		},
		{ 
			path: '/refabsensiswa/edit/:id', 
			name: 'refabsensiswaedit', 
			component: () => import('pages/refabsensiswa/edit.vue'), 
			props: true 
		},

//refabsguru routes
		{
			path: '/refabsguru/:fieldName?/:fieldValue?',
			name: 'refabsgurulist',
			component: () => import('pages/refabsguru/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refabsguru/view/:id', 
			name: 'refabsguruview', 
			component: () => import('pages/refabsguru/view.vue'), 
			props: true 
		},
		{ 
			path: '/refabsguru/add', 
			name: 'refabsguruadd', 
			component: () => import('pages/refabsguru/add.vue'), 
			props: true 
		},
		{ 
			path: '/refabsguru/edit/:id', 
			name: 'refabsguruedit', 
			component: () => import('pages/refabsguru/edit.vue'), 
			props: true 
		},

//refbayar routes
		{
			path: '/refbayar/:fieldName?/:fieldValue?',
			name: 'refbayarlist',
			component: () => import('pages/refbayar/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refbayar/view/:id', 
			name: 'refbayarview', 
			component: () => import('pages/refbayar/view.vue'), 
			props: true 
		},
		{ 
			path: '/refbayar/add', 
			name: 'refbayaradd', 
			component: () => import('pages/refbayar/add.vue'), 
			props: true 
		},
		{ 
			path: '/refbayar/edit/:id', 
			name: 'refbayaredit', 
			component: () => import('pages/refbayar/edit.vue'), 
			props: true 
		},

//refhari routes
		{
			path: '/refhari/:fieldName?/:fieldValue?',
			name: 'refharilist',
			component: () => import('pages/refhari/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refhari/view/:id', 
			name: 'refhariview', 
			component: () => import('pages/refhari/view.vue'), 
			props: true 
		},
		{ 
			path: '/refhari/add', 
			name: 'refhariadd', 
			component: () => import('pages/refhari/add.vue'), 
			props: true 
		},
		{ 
			path: '/refhari/edit/:id', 
			name: 'refhariedit', 
			component: () => import('pages/refhari/edit.vue'), 
			props: true 
		},

//refjpel routes
		{
			path: '/refjpel/:fieldName?/:fieldValue?',
			name: 'refjpellist',
			component: () => import('pages/refjpel/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refjpel/view/:id', 
			name: 'refjpelview', 
			component: () => import('pages/refjpel/view.vue'), 
			props: true 
		},
		{ 
			path: '/refjpel/add', 
			name: 'refjpeladd', 
			component: () => import('pages/refjpel/add.vue'), 
			props: true 
		},
		{ 
			path: '/refjpel/edit/:id', 
			name: 'refjpeledit', 
			component: () => import('pages/refjpel/edit.vue'), 
			props: true 
		},

//refkelas routes
		{
			path: '/refkelas/:fieldName?/:fieldValue?',
			name: 'refkelaslist',
			component: () => import('pages/refkelas/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refkelas/view/:id', 
			name: 'refkelasview', 
			component: () => import('pages/refkelas/view.vue'), 
			props: true 
		},
		{ 
			path: '/refkelas/add', 
			name: 'refkelasadd', 
			component: () => import('pages/refkelas/add.vue'), 
			props: true 
		},
		{ 
			path: '/refkelas/edit/:id', 
			name: 'refkelasedit', 
			component: () => import('pages/refkelas/edit.vue'), 
			props: true 
		},

//refmatpel routes
		{
			path: '/refmatpel/:fieldName?/:fieldValue?',
			name: 'refmatpellist',
			component: () => import('pages/refmatpel/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refmatpel/view/:id', 
			name: 'refmatpelview', 
			component: () => import('pages/refmatpel/view.vue'), 
			props: true 
		},
		{ 
			path: '/refmatpel/add', 
			name: 'refmatpeladd', 
			component: () => import('pages/refmatpel/add.vue'), 
			props: true 
		},
		{ 
			path: '/refmatpel/edit/:id', 
			name: 'refmatpeledit', 
			component: () => import('pages/refmatpel/edit.vue'), 
			props: true 
		},

//refsemester routes
		{
			path: '/refsemester/:fieldName?/:fieldValue?',
			name: 'refsemesterlist',
			component: () => import('pages/refsemester/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refsemester/view/:id', 
			name: 'refsemesterview', 
			component: () => import('pages/refsemester/view.vue'), 
			props: true 
		},
		{ 
			path: '/refsemester/add', 
			name: 'refsemesteradd', 
			component: () => import('pages/refsemester/add.vue'), 
			props: true 
		},
		{ 
			path: '/refsemester/edit/:id', 
			name: 'refsemesteredit', 
			component: () => import('pages/refsemester/edit.vue'), 
			props: true 
		},

//roles routes
		{
			path: '/roles/:fieldName?/:fieldValue?',
			name: 'roleslist',
			component: () => import('pages/roles/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/roles/view/:id', 
			name: 'rolesview', 
			component: () => import('pages/roles/view.vue'), 
			props: true 
		},
		{ 
			path: '/roles/add', 
			name: 'rolesadd', 
			component: () => import('pages/roles/add.vue'), 
			props: true 
		},
		{ 
			path: '/roles/edit/:id', 
			name: 'rolesedit', 
			component: () => import('pages/roles/edit.vue'), 
			props: true 
		},

//tbabsensiswa routes
		{
			path: '/tbabsensiswa/:fieldName?/:fieldValue?',
			name: 'tbabsensiswalist',
			component: () => import('pages/tbabsensiswa/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbabsensiswa/view/:id', 
			name: 'tbabsensiswaview', 
			component: () => import('pages/tbabsensiswa/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbabsensiswa/add', 
			name: 'tbabsensiswaadd', 
			component: () => import('pages/tbabsensiswa/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbabsensiswa/edit/:id', 
			name: 'tbabsensiswaedit', 
			component: () => import('pages/tbabsensiswa/edit.vue'), 
			props: true 
		},

//tbakunewallet routes
		{
			path: '/tbakunewallet/:fieldName?/:fieldValue?',
			name: 'tbakunewalletlist',
			component: () => import('pages/tbakunewallet/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbakunewallet/view/:id', 
			name: 'tbakunewalletview', 
			component: () => import('pages/tbakunewallet/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbakunewallet/add', 
			name: 'tbakunewalletadd', 
			component: () => import('pages/tbakunewallet/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbakunewallet/edit/:id', 
			name: 'tbakunewalletedit', 
			component: () => import('pages/tbakunewallet/edit.vue'), 
			props: true 
		},

//tbcolecttugas routes
		{
			path: '/tbcolecttugas/:fieldName?/:fieldValue?',
			name: 'tbcolecttugaslist',
			component: () => import('pages/tbcolecttugas/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbcolecttugas/view/:id', 
			name: 'tbcolecttugasview', 
			component: () => import('pages/tbcolecttugas/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbcolecttugas/add', 
			name: 'tbcolecttugasadd', 
			component: () => import('pages/tbcolecttugas/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbcolecttugas/edit/:id', 
			name: 'tbcolecttugasedit', 
			component: () => import('pages/tbcolecttugas/edit.vue'), 
			props: true 
		},

//tbdigitallit routes
		{
			path: '/tbdigitallit/:fieldName?/:fieldValue?',
			name: 'tbdigitallitlist',
			component: () => import('pages/tbdigitallit/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbdigitallit/view/:id', 
			name: 'tbdigitallitview', 
			component: () => import('pages/tbdigitallit/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbdigitallit/add', 
			name: 'tbdigitallitadd', 
			component: () => import('pages/tbdigitallit/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbdigitallit/edit/:id', 
			name: 'tbdigitallitedit', 
			component: () => import('pages/tbdigitallit/edit.vue'), 
			props: true 
		},

//tbguru routes
		{
			path: '/tbguru/:fieldName?/:fieldValue?',
			name: 'tbgurulist',
			component: () => import('pages/tbguru/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbguru/view/:id', 
			name: 'tbguruview', 
			component: () => import('pages/tbguru/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbguru/add', 
			name: 'tbguruadd', 
			component: () => import('pages/tbguru/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbguru/edit/:id', 
			name: 'tbguruedit', 
			component: () => import('pages/tbguru/edit.vue'), 
			props: true 
		},

//tbjadwal routes
		{
			path: '/tbjadwal/:fieldName?/:fieldValue?',
			name: 'tbjadwallist',
			component: () => import('pages/tbjadwal/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbjadwal/view/:id', 
			name: 'tbjadwalview', 
			component: () => import('pages/tbjadwal/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbjadwal/add', 
			name: 'tbjadwaladd', 
			component: () => import('pages/tbjadwal/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbjadwal/edit/:id', 
			name: 'tbjadwaledit', 
			component: () => import('pages/tbjadwal/edit.vue'), 
			props: true 
		},

//tbjadwalpel routes
		{
			path: '/tbjadwalpel/:fieldName?/:fieldValue?',
			name: 'tbjadwalpellist',
			component: () => import('pages/tbjadwalpel/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbjadwalpel/view/:id', 
			name: 'tbjadwalpelview', 
			component: () => import('pages/tbjadwalpel/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbjadwalpel/add', 
			name: 'tbjadwalpeladd', 
			component: () => import('pages/tbjadwalpel/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbjadwalpel/edit/:id', 
			name: 'tbjadwalpeledit', 
			component: () => import('pages/tbjadwalpel/edit.vue'), 
			props: true 
		},

//tbkalkegiatan routes
		{
			path: '/tbkalkegiatan/:fieldName?/:fieldValue?',
			name: 'tbkalkegiatanlist',
			component: () => import('pages/tbkalkegiatan/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbkalkegiatan/view/:id', 
			name: 'tbkalkegiatanview', 
			component: () => import('pages/tbkalkegiatan/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbkalkegiatan/add', 
			name: 'tbkalkegiatanadd', 
			component: () => import('pages/tbkalkegiatan/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbkalkegiatan/edit/:id', 
			name: 'tbkalkegiatanedit', 
			component: () => import('pages/tbkalkegiatan/edit.vue'), 
			props: true 
		},

//tbleger routes
		{
			path: '/tbleger/:fieldName?/:fieldValue?',
			name: 'tblegerlist',
			component: () => import('pages/tbleger/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbleger/view/:id', 
			name: 'tblegerview', 
			component: () => import('pages/tbleger/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbleger/add', 
			name: 'tblegeradd', 
			component: () => import('pages/tbleger/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbleger/edit/:id', 
			name: 'tblegeredit', 
			component: () => import('pages/tbleger/edit.vue'), 
			props: true 
		},

//tblogbayar routes
		{
			path: '/tblogbayar/:fieldName?/:fieldValue?',
			name: 'tblogbayarlist',
			component: () => import('pages/tblogbayar/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tblogbayar/view/:id', 
			name: 'tblogbayarview', 
			component: () => import('pages/tblogbayar/view.vue'), 
			props: true 
		},
		{ 
			path: '/tblogbayar/add', 
			name: 'tblogbayaradd', 
			component: () => import('pages/tblogbayar/add.vue'), 
			props: true 
		},
		{ 
			path: '/tblogbayar/edit/:id', 
			name: 'tblogbayaredit', 
			component: () => import('pages/tblogbayar/edit.vue'), 
			props: true 
		},

//tbmutasiewalet routes
		{
			path: '/tbmutasiewalet/:fieldName?/:fieldValue?',
			name: 'tbmutasiewaletlist',
			component: () => import('pages/tbmutasiewalet/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbmutasiewalet/view/:id', 
			name: 'tbmutasiewaletview', 
			component: () => import('pages/tbmutasiewalet/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbmutasiewalet/add', 
			name: 'tbmutasiewaletadd', 
			component: () => import('pages/tbmutasiewalet/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbmutasiewalet/edit/:id', 
			name: 'tbmutasiewaletedit', 
			component: () => import('pages/tbmutasiewalet/edit.vue'), 
			props: true 
		},

//tbnilaiharian routes
		{
			path: '/tbnilaiharian/:fieldName?/:fieldValue?',
			name: 'tbnilaiharianlist',
			component: () => import('pages/tbnilaiharian/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbnilaiharian/view/:id', 
			name: 'tbnilaiharianview', 
			component: () => import('pages/tbnilaiharian/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbnilaiharian/add', 
			name: 'tbnilaiharianadd', 
			component: () => import('pages/tbnilaiharian/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbnilaiharian/edit/:id', 
			name: 'tbnilaiharianedit', 
			component: () => import('pages/tbnilaiharian/edit.vue'), 
			props: true 
		},

//tbnilakhir routes
		{
			path: '/tbnilakhir/:fieldName?/:fieldValue?',
			name: 'tbnilakhirlist',
			component: () => import('pages/tbnilakhir/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbnilakhir/view/:id', 
			name: 'tbnilakhirview', 
			component: () => import('pages/tbnilakhir/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbnilakhir/add', 
			name: 'tbnilakhiradd', 
			component: () => import('pages/tbnilakhir/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbnilakhir/edit/:id', 
			name: 'tbnilakhiredit', 
			component: () => import('pages/tbnilakhir/edit.vue'), 
			props: true 
		},

//tborangtua routes
		{
			path: '/tborangtua/:fieldName?/:fieldValue?',
			name: 'tborangtualist',
			component: () => import('pages/tborangtua/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tborangtua/view/:id', 
			name: 'tborangtuaview', 
			component: () => import('pages/tborangtua/view.vue'), 
			props: true 
		},
		{ 
			path: '/tborangtua/add', 
			name: 'tborangtuaadd', 
			component: () => import('pages/tborangtua/add.vue'), 
			props: true 
		},
		{ 
			path: '/tborangtua/edit/:id', 
			name: 'tborangtuaedit', 
			component: () => import('pages/tborangtua/edit.vue'), 
			props: true 
		},

//tbsabsen routes
		{
			path: '/tbsabsen/:fieldName?/:fieldValue?',
			name: 'tbsabsenlist',
			component: () => import('pages/tbsabsen/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbsabsen/view/:id', 
			name: 'tbsabsenview', 
			component: () => import('pages/tbsabsen/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbsabsen/add', 
			name: 'tbsabsenadd', 
			component: () => import('pages/tbsabsen/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbsabsen/edit/:id', 
			name: 'tbsabsenedit', 
			component: () => import('pages/tbsabsen/edit.vue'), 
			props: true 
		},

//tbsiswa routes
		{
			path: '/tbsiswa/:fieldName?/:fieldValue?',
			name: 'tbsiswalist',
			component: () => import('pages/tbsiswa/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbsiswa/view/:id', 
			name: 'tbsiswaview', 
			component: () => import('pages/tbsiswa/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbsiswa/add', 
			name: 'tbsiswaadd', 
			component: () => import('pages/tbsiswa/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbsiswa/edit/:id', 
			name: 'tbsiswaedit', 
			component: () => import('pages/tbsiswa/edit.vue'), 
			props: true 
		},

//tbtabsiswa routes
		{
			path: '/tbtabsiswa/:fieldName?/:fieldValue?',
			name: 'tbtabsiswalist',
			component: () => import('pages/tbtabsiswa/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbtabsiswa/view/:id', 
			name: 'tbtabsiswaview', 
			component: () => import('pages/tbtabsiswa/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbtabsiswa/add', 
			name: 'tbtabsiswaadd', 
			component: () => import('pages/tbtabsiswa/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbtabsiswa/edit/:id', 
			name: 'tbtabsiswaedit', 
			component: () => import('pages/tbtabsiswa/edit.vue'), 
			props: true 
		},

//tbtugas routes
		{
			path: '/tbtugas/:fieldName?/:fieldValue?',
			name: 'tbtugaslist',
			component: () => import('pages/tbtugas/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/tbtugas/view/:id', 
			name: 'tbtugasview', 
			component: () => import('pages/tbtugas/view.vue'), 
			props: true 
		},
		{ 
			path: '/tbtugas/add', 
			name: 'tbtugasadd', 
			component: () => import('pages/tbtugas/add.vue'), 
			props: true 
		},
		{ 
			path: '/tbtugas/edit/:id', 
			name: 'tbtugasedit', 
			component: () => import('pages/tbtugas/edit.vue'), 
			props: true 
		},

//tindividu routes
		{
			path: '/tindividu/:fieldName?/:fieldValue?',
			name: 'tindividulist',
			component: () => import('pages/tindividu/list.vue'),
			props: route => passRouteToProps(route)
		},

//tkelompok routes
		{
			path: '/tkelompok/:fieldName?/:fieldValue?',
			name: 'tkelompoklist',
			component: () => import('pages/tkelompok/list.vue'),
			props: route => passRouteToProps(route)
		},

//tranewallet routes
		{
			path: '/tranewallet/:fieldName?/:fieldValue?',
			name: 'tranewalletlist',
			component: () => import('pages/tranewallet/list.vue'),
			props: route => passRouteToProps(route)
		},

//users routes
		{
			path: '/users/:fieldName?/:fieldValue?',
			name: 'userslist',
			component: () => import('pages/users/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users/view/:id', 
			name: 'usersview', 
			component: () => import('pages/users/view.vue'), 
			props: true 
		},
		{ 
			path: '/account/edit', 
			name: 'usersaccountedit', 
			component: () => import('pages/account/accountedit.vue'), 
			props: true 
		},
		{ 
			path: '/account', 
			name: 'usersaccountview', 
			component: () => import('pages/account/accountview.vue'), 
			props: true 
		},
		{ 
			path: '/users/add', 
			name: 'usersadd', 
			component: () => import('pages/users/add.vue'), 
			props: true 
		},
		{ 
			path: '/users/edit/:id', 
			name: 'usersedit', 
			component: () => import('pages/users/edit.vue'), 
			props: true 
		},

//vabsensiswa routes
		{
			path: '/vabsensiswa/:fieldName?/:fieldValue?',
			name: 'vabsensiswalist',
			component: () => import('pages/vabsensiswa/list.vue'),
			props: route => passRouteToProps(route)
		},

//vkaldik routes
		{
			path: '/vkaldik/:fieldName?/:fieldValue?',
			name: 'vkaldiklist',
			component: () => import('pages/vkaldik/list.vue'),
			props: route => passRouteToProps(route)
		},

//vtabsiswa routes
		{
			path: '/vtabsiswa/:fieldName?/:fieldValue?',
			name: 'vtabsiswalist',
			component: () => import('pages/vtabsiswa/list.vue'),
			props: route => passRouteToProps(route)
		},

		{ 
			path: '/jadwal_pelajaran', 
			name: 'jadwal_pelajaran', 
			component: () => import('pages/custom/jadwal_pelajaran.vue'), 
			props: true 
		},
		{ 
			path: '/raport_siswa', 
			name: 'raport_siswa', 
			component: () => import('pages/custom/raport_siswa.vue'), 
			props: true 
		},
		{ 
			path: '/keuangan', 
			name: 'keuangan', 
			component: () => import('pages/custom/keuangan.vue'), 
			props: true 
		},
		{ 
			path: '/keu_siswa', 
			name: 'keu_siswa', 
			component: () => import('pages/custom/keu_siswa.vue'), 
			props: true 
		},
		{ 
			path: '/tugas_siswa', 
			name: 'tugas_siswa', 
			component: () => import('pages/custom/tugas_siswa.vue'), 
			props: true 
		},
		{ 
			path: '/database', 
			name: 'database', 
			component: () => import('pages/custom/database.vue'), 
			props: true 
		},
		{ 
			path: '/absen_siswa', 
			name: 'absen_siswa', 
			component: () => import('pages/custom/absen_siswa.vue'), 
			props: true 
		},
		{ 
			path: '/admguru', 
			name: 'admguru', 
			component: () => import('pages/custom/admguru.vue'), 
			props: true 
		},
		{ 
			path: '/ref_input', 
			name: 'ref_input', 
			component: () => import('pages/custom/ref_input.vue'), 
			props: true 
		},
		{ 
			path: '/literasi_digital', 
			name: 'literasi_digital', 
			component: () => import('pages/custom/literasi_digital.vue'), 
			props: true 
		},
		{ 
			path: '/tabungan_siswa', 
			name: 'tabungan_siswa', 
			component: () => import('pages/custom/tabungan_siswa.vue'), 
			props: true 
		},
		{ 
			path: '/ewallet', 
			name: 'ewallet', 
			component: () => import('pages/custom/ewallet.vue'), 
			props: true 
		},
		{ 
			path: '/leger_siswa', 
			name: 'leger_siswa', 
			component: () => import('pages/custom/leger_siswa.vue'), 
			props: true 
		},
		
		
//Password reset routes
		{ 
			path: '/index/forgotpassword', 
			name: 'forgotpassword', 
			component: () => import('pages/index/forgotpassword.vue') 
		},
		{ 
			path: '/index/resetpassword', 
			name: 'resetpassword', 
			component: () => import('pages/index/resetpassword.vue') 
		},
		{ 
			path: '/index/resetpassword_completed', 
			name: 'resetpassword_completed', 
			component: () => import('pages/index/resetpassword_completed.vue') 
		},
		
		

		//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE == 'history' ? createWebHistory : createWebHashHistory)
	
	const mainRoute = routes.find(x => x.name = "main");
	
	const auth = useAuth();

	
	//get current user data from api on first load
	const { user } = await auth.getUserData();
	if(user){
		const homeRoute = {
			path: '/', 
			alias: '/home', 
			name: 'home', 
			component: () => import('pages/home/home.vue') 
		}
		mainRoute.children.push(homeRoute);
	}
	else{
		const indexRoute = { 
			path: '/',  
			alias: '/home', 
			name: 'index', 
			component: () => import('pages/index/index.vue') 
		}
		mainRoute.children.push(indexRoute);
	}

	const Router = createRouter({
	scrollBehavior(to, from, savedPostion){
		if(savedPostion) return savedPostion;
		return { top:0 }
	},
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})

