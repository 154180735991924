
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "home",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/ref_input",
    "label": "Tabel Referensi",
    "icon": "playlist_add",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/refabsguru",
        "label": "Ref Absen Guru",
        "icon": "access_time",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/refabsensiswa",
        "label": "ref Absen Siswa",
        "icon": "access_time",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/refjpel",
        "label": "ref Jam Pelajaran",
        "icon": "add_alarm",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/refmatpel",
        "label": "ref Matapelajaran",
        "icon": "beenhere",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/refkelas",
        "label": "Ref Kelas",
        "icon": "business",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/refsemester",
        "label": "Ref Semester",
        "icon": "extension",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/database",
    "label": "Database",
    "icon": "dns",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/tbsiswa",
        "label": "Database Siswa",
        "icon": "people",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/tborangtua",
        "label": "Database Orangtua",
        "icon": "people",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/tbguru",
        "label": "Database Guru",
        "icon": "people",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/vkaldik",
    "label": "Kalender Kegiatan",
    "icon": "event_note",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/jadwal_pelajaran",
    "label": "Jadwal Pelajaran",
    "icon": "event",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/vabsensiswa",
    "label": "Absensi Siswa",
    "icon": "event_available",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/literasi_digital",
    "label": "Digital Literasi",
    "icon": "local_library",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/tugas_siswa",
    "label": "Tugas",
    "icon": "assignment",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/admguru",
    "label": "Administrasi Guru",
    "icon": "assignment_ind",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/tbabsensiswa",
        "label": "Absen Siswa",
        "icon": "verified_user",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/tbcolecttugas",
        "label": "Hasil Tugas",
        "icon": "assignment_turned_in",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/tbnilaiharian",
        "label": "Nilai Harian",
        "icon": "playlist_add",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/tbnilakhir",
        "label": "Nilai Akhir",
        "icon": "assessment",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/tbtugas/add",
        "label": "Input tugas",
        "icon": "note_add",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/leger_siswa",
    "label": "Leger",
    "icon": "book",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/raport_siswa",
    "label": "Raport",
    "icon": "assessment",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/keuangan",
    "label": "Keuangan",
    "icon": "style",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/tblogbayar",
        "label": "History Pembayaran",
        "icon": "history",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  },
  {
    "path": "/keu_siswa",
    "label": "keuangan Siswa",
    "icon": "assistant",
    "iconcolor": "",
    "target": "",
    "submenu": [
      {
        "path": "/tabungan_siswa",
        "label": "Tabungan Siswa",
        "icon": "move_to_inbox",
        "iconcolor": "",
        "target": "",
        "submenu": []
      },
      {
        "path": "/ewallet",
        "label": "E-Wallet",
        "icon": "account_balance_wallet",
        "iconcolor": "",
        "target": "",
        "submenu": []
      }
    ]
  }
],
	jdwselasalistheader: [
  {
    "label": "Selasa",
    "align": "left",
    "sortable": false,
    "name": "matpel",
    "field": "matpel"
  },
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "kd_guru",
    "field": "kd_guru"
  }
],
	jdwseninlistheader: [
  {
    "label": "Senin",
    "align": "left",
    "sortable": false,
    "name": "matpel",
    "field": "matpel"
  },
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "kd_guru",
    "field": "kd_guru"
  }
],
	legerlistheader: [
  {
    "label": "Id Leger",
    "align": "left",
    "sortable": false,
    "name": "id_leger",
    "field": "id_leger"
  },
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "nama",
    "field": "nama"
  },
  {
    "label": "Pai",
    "align": "left",
    "sortable": false,
    "name": "pai",
    "field": "pai"
  },
  {
    "label": "Ppkn",
    "align": "left",
    "sortable": false,
    "name": "ppkn",
    "field": "ppkn"
  },
  {
    "label": "B Indonesia",
    "align": "left",
    "sortable": false,
    "name": "b_indonesia",
    "field": "b_indonesia"
  },
  {
    "label": "Mtk",
    "align": "left",
    "sortable": false,
    "name": "mtk",
    "field": "mtk"
  },
  {
    "label": "Ipa",
    "align": "left",
    "sortable": false,
    "name": "ipa",
    "field": "ipa"
  },
  {
    "label": "Ips",
    "align": "left",
    "sortable": false,
    "name": "ips",
    "field": "ips"
  },
  {
    "label": "Seni Budaya",
    "align": "left",
    "sortable": false,
    "name": "seni_budaya",
    "field": "seni_budaya"
  },
  {
    "label": "Pjok",
    "align": "left",
    "sortable": false,
    "name": "pjok",
    "field": "pjok"
  },
  {
    "label": "B Inggris",
    "align": "left",
    "sortable": false,
    "name": "b_inggris",
    "field": "b_inggris"
  },
  {
    "label": "B Jawa",
    "align": "left",
    "sortable": false,
    "name": "b_jawa",
    "field": "b_jawa"
  },
  {
    "label": "Prakarya",
    "align": "left",
    "sortable": false,
    "name": "prakarya",
    "field": "prakarya"
  },
  {
    "label": "Tik",
    "align": "left",
    "sortable": false,
    "name": "tik",
    "field": "tik"
  },
  {
    "label": "Log",
    "align": "left",
    "sortable": false,
    "name": "log",
    "field": "log"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	permissionslistheader: [
  {
    "label": "Permission Id",
    "align": "left",
    "sortable": false,
    "name": "permission_id",
    "field": "permission_id"
  },
  {
    "label": "Permission",
    "align": "left",
    "sortable": false,
    "name": "permission",
    "field": "permission"
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refabsensiswalistheader: [
  {
    "label": "Id Abssiswa",
    "align": "left",
    "sortable": false,
    "name": "id_abssiswa",
    "field": "id_abssiswa"
  },
  {
    "label": "N Absensiswa",
    "align": "left",
    "sortable": false,
    "name": "n_absensiswa",
    "field": "n_absensiswa"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refabsgurulistheader: [
  {
    "label": "Id Absguru",
    "align": "left",
    "sortable": false,
    "name": "id_absguru",
    "field": "id_absguru"
  },
  {
    "label": "N Abs Guru",
    "align": "left",
    "sortable": false,
    "name": "n_abs_guru",
    "field": "n_abs_guru"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refbayarlistheader: [
  {
    "label": "Id Bayar",
    "align": "left",
    "sortable": false,
    "name": "id_bayar",
    "field": "id_bayar"
  },
  {
    "label": "Itembayar",
    "align": "left",
    "sortable": false,
    "name": "itembayar",
    "field": "itembayar"
  },
  {
    "label": "J Bayar",
    "align": "left",
    "sortable": false,
    "name": "j_bayar",
    "field": "j_bayar"
  },
  {
    "label": "Ket Bayar",
    "align": "left",
    "sortable": false,
    "name": "ket_bayar",
    "field": "ket_bayar"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refharilistheader: [
  {
    "label": "Id Hari",
    "align": "left",
    "sortable": false,
    "name": "id_hari",
    "field": "id_hari"
  },
  {
    "label": "Nama Hari",
    "align": "left",
    "sortable": false,
    "name": "nama_hari",
    "field": "nama_hari"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refjpellistheader: [
  {
    "label": "Id Jpel",
    "align": "left",
    "sortable": false,
    "name": "id_jpel",
    "field": "id_jpel"
  },
  {
    "label": "Jam Pel",
    "align": "left",
    "sortable": false,
    "name": "jam_pel",
    "field": "jam_pel"
  },
  {
    "label": "Jpel Mulai",
    "align": "left",
    "sortable": false,
    "name": "jpel_mulai",
    "field": "jpel_mulai"
  },
  {
    "label": "Jpel Selesai",
    "align": "left",
    "sortable": false,
    "name": "jpel_selesai",
    "field": "jpel_selesai"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refkelaslistheader: [
  {
    "label": "Id Kelas",
    "align": "left",
    "sortable": false,
    "name": "id_kelas",
    "field": "id_kelas"
  },
  {
    "label": "N Kelas",
    "align": "left",
    "sortable": false,
    "name": "n_kelas",
    "field": "n_kelas"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refmatpellistheader: [
  {
    "label": "Id Matpel",
    "align": "left",
    "sortable": false,
    "name": "id_matpel",
    "field": "id_matpel"
  },
  {
    "label": "Matpel",
    "align": "left",
    "sortable": false,
    "name": "matpel",
    "field": "matpel"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refsemesterlistheader: [
  {
    "label": "Id Refsem",
    "align": "left",
    "sortable": false,
    "name": "id_refsem",
    "field": "id_refsem"
  },
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "nama",
    "field": "nama"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	roleslistheader: [
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "Role Name",
    "align": "left",
    "sortable": false,
    "name": "role_name",
    "field": "role_name"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	kdAbsen: [    
{value: "1", label: "Sakit"},
	{value: "2", label: "Izin"}
    ],
	tbabsensiswalistheader: [
  {
    "label": "Id Abs Siswa",
    "align": "left",
    "sortable": false,
    "name": "id_abs_siswa",
    "field": "id_abs_siswa"
  },
  {
    "label": "Siswa Id",
    "align": "left",
    "sortable": false,
    "name": "siswa_id",
    "field": "siswa_id"
  },
  {
    "label": "Tgl Absen",
    "align": "left",
    "sortable": false,
    "name": "tgl_absen",
    "field": "tgl_absen"
  },
  {
    "label": "Kd Absen",
    "align": "left",
    "sortable": false,
    "name": "kd_absen",
    "field": "kd_absen"
  },
  {
    "label": "Keterangan",
    "align": "left",
    "sortable": false,
    "name": "keterangan",
    "field": "keterangan"
  },
  {
    "label": "Apv Ortu",
    "align": "left",
    "sortable": false,
    "name": "apv_ortu",
    "field": "apv_ortu"
  },
  {
    "label": "Apv Guru",
    "align": "left",
    "sortable": false,
    "name": "apv_guru",
    "field": "apv_guru"
  },
  {
    "label": "Log",
    "align": "left",
    "sortable": false,
    "name": "log",
    "field": "log"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbakunewalletlistheader: [
  {
    "label": "Id Akun",
    "align": "left",
    "sortable": false,
    "name": "id_akun",
    "field": "id_akun"
  },
  {
    "label": "Nama Akun",
    "align": "left",
    "sortable": false,
    "name": "nama_akun",
    "field": "nama_akun"
  },
  {
    "label": "No Akun",
    "align": "left",
    "sortable": false,
    "name": "no_akun",
    "field": "no_akun"
  },
  {
    "label": "Exp Bln",
    "align": "left",
    "sortable": false,
    "name": "exp_bln",
    "field": "exp_bln"
  },
  {
    "label": "Exp Thn",
    "align": "left",
    "sortable": false,
    "name": "exp_thn",
    "field": "exp_thn"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbcolecttugaslistheader: [
  {
    "label": "Id Coltugas",
    "align": "left",
    "sortable": false,
    "name": "id_coltugas",
    "field": "id_coltugas"
  },
  {
    "label": "Tugas Id",
    "align": "left",
    "sortable": false,
    "name": "tugas_id",
    "field": "tugas_id"
  },
  {
    "label": "Siswa Id",
    "align": "left",
    "sortable": false,
    "name": "siswa_id",
    "field": "siswa_id"
  },
  {
    "label": "Tgl Colect",
    "align": "left",
    "sortable": false,
    "name": "tgl_colect",
    "field": "tgl_colect"
  },
  {
    "label": "Hasil Tugas",
    "align": "left",
    "sortable": false,
    "name": "hasil_tugas",
    "field": "hasil_tugas"
  },
  {
    "label": "Log Colect",
    "align": "left",
    "sortable": false,
    "name": "log_colect",
    "field": "log_colect"
  },
  {
    "label": "Kelas Id",
    "align": "left",
    "sortable": false,
    "name": "kelas_id",
    "field": "kelas_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbdigitallitlistheader: [
  {
    "label": "Id Litdig",
    "align": "left",
    "sortable": false,
    "name": "id_litdig",
    "field": "id_litdig"
  },
  {
    "label": "Title Litdig",
    "align": "left",
    "sortable": false,
    "name": "title_litdig",
    "field": "title_litdig"
  },
  {
    "label": "Matpel Id",
    "align": "left",
    "sortable": false,
    "name": "matpel_id",
    "field": "matpel_id"
  },
  {
    "label": "Guru Id",
    "align": "left",
    "sortable": false,
    "name": "guru_id",
    "field": "guru_id"
  },
  {
    "label": "Sumber",
    "align": "left",
    "sortable": false,
    "name": "sumber",
    "field": "sumber"
  },
  {
    "label": "Litdig Ket",
    "align": "left",
    "sortable": false,
    "name": "litdig_ket",
    "field": "litdig_ket"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbgurulistheader: [
  {
    "label": "Id Guru",
    "align": "left",
    "sortable": false,
    "name": "id_guru",
    "field": "id_guru"
  },
  {
    "label": "Id Matpel",
    "align": "left",
    "sortable": false,
    "name": "id_matpel",
    "field": "id_matpel"
  },
  {
    "label": "N Guru",
    "align": "left",
    "sortable": false,
    "name": "n_guru",
    "field": "n_guru"
  },
  {
    "label": "Nip",
    "align": "left",
    "sortable": false,
    "name": "nip",
    "field": "nip"
  },
  {
    "label": "Id Peg",
    "align": "left",
    "sortable": false,
    "name": "id_peg",
    "field": "id_peg"
  },
  {
    "label": "Peg Status",
    "align": "left",
    "sortable": false,
    "name": "peg_status",
    "field": "peg_status"
  },
  {
    "label": "J Kelamin",
    "align": "left",
    "sortable": false,
    "name": "j_kelamin",
    "field": "j_kelamin"
  },
  {
    "label": "Tmp Lahirguru",
    "align": "left",
    "sortable": false,
    "name": "tmp_lahirguru",
    "field": "tmp_lahirguru"
  },
  {
    "label": "Tgl Lahirguru",
    "align": "left",
    "sortable": false,
    "name": "tgl_lahirguru",
    "field": "tgl_lahirguru"
  },
  {
    "label": "Tmt Guru",
    "align": "left",
    "sortable": false,
    "name": "tmt_guru",
    "field": "tmt_guru"
  },
  {
    "label": "Kualifikasi",
    "align": "left",
    "sortable": false,
    "name": "kualifikasi",
    "field": "kualifikasi"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbjadwallistheader: [
  {
    "label": "Id Jdwajar",
    "align": "left",
    "sortable": false,
    "name": "id_jdwajar",
    "field": "id_jdwajar"
  },
  {
    "label": "Jam Id",
    "align": "left",
    "sortable": false,
    "name": "jam_id",
    "field": "jam_id"
  },
  {
    "label": "Hari Id",
    "align": "left",
    "sortable": false,
    "name": "hari_id",
    "field": "hari_id"
  },
  {
    "label": "Matpel Id",
    "align": "left",
    "sortable": false,
    "name": "matpel_id",
    "field": "matpel_id"
  },
  {
    "label": "Log",
    "align": "left",
    "sortable": false,
    "name": "log",
    "field": "log"
  },
  {
    "label": "Kd Guru",
    "align": "left",
    "sortable": false,
    "name": "kd_guru",
    "field": "kd_guru"
  },
  {
    "label": "Kd Kelas",
    "align": "left",
    "sortable": false,
    "name": "kd_kelas",
    "field": "kd_kelas"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbjadwalpellistheader: [
  {
    "label": "Id Jadwal",
    "align": "left",
    "sortable": false,
    "name": "id_jadwal",
    "field": "id_jadwal"
  },
  {
    "label": "Kelas Id",
    "align": "left",
    "sortable": false,
    "name": "kelas_id",
    "field": "kelas_id"
  },
  {
    "label": "Guru Id",
    "align": "left",
    "sortable": false,
    "name": "guru_id",
    "field": "guru_id"
  },
  {
    "label": "Matpel Id",
    "align": "left",
    "sortable": false,
    "name": "matpel_id",
    "field": "matpel_id"
  },
  {
    "label": "Jpel Id",
    "align": "left",
    "sortable": false,
    "name": "jpel_id",
    "field": "jpel_id"
  },
  {
    "label": "Hari Pel",
    "align": "left",
    "sortable": false,
    "name": "hari_pel",
    "field": "hari_pel"
  },
  {
    "label": "Log Jdwpel",
    "align": "left",
    "sortable": false,
    "name": "log_jdwpel",
    "field": "log_jdwpel"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbkalkegiatanlistheader: [
  {
    "label": "Id Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "id_kegiatan",
    "field": "id_kegiatan"
  },
  {
    "label": "Title Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "title_kegiatan",
    "field": "title_kegiatan"
  },
  {
    "label": "Tgl Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "tgl_kegiatan",
    "field": "tgl_kegiatan"
  },
  {
    "label": "Pic Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "pic_kegiatan",
    "field": "pic_kegiatan"
  },
  {
    "label": "Time Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "time_kegiatan",
    "field": "time_kegiatan"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tblegerlistheader: [
  {
    "label": "Id Leger",
    "align": "left",
    "sortable": false,
    "name": "id_leger",
    "field": "id_leger"
  },
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "nama",
    "field": "nama"
  },
  {
    "label": "Pai",
    "align": "left",
    "sortable": false,
    "name": "pai",
    "field": "pai"
  },
  {
    "label": "Ppkn",
    "align": "left",
    "sortable": false,
    "name": "ppkn",
    "field": "ppkn"
  },
  {
    "label": "B Indonesia",
    "align": "left",
    "sortable": false,
    "name": "b_indonesia",
    "field": "b_indonesia"
  },
  {
    "label": "Mtk",
    "align": "left",
    "sortable": false,
    "name": "mtk",
    "field": "mtk"
  },
  {
    "label": "Ipa",
    "align": "left",
    "sortable": false,
    "name": "ipa",
    "field": "ipa"
  },
  {
    "label": "Ips",
    "align": "left",
    "sortable": false,
    "name": "ips",
    "field": "ips"
  },
  {
    "label": "Seni Budaya",
    "align": "left",
    "sortable": false,
    "name": "seni_budaya",
    "field": "seni_budaya"
  },
  {
    "label": "Pjok",
    "align": "left",
    "sortable": false,
    "name": "pjok",
    "field": "pjok"
  },
  {
    "label": "B Inggris",
    "align": "left",
    "sortable": false,
    "name": "b_inggris",
    "field": "b_inggris"
  },
  {
    "label": "B Jawa",
    "align": "left",
    "sortable": false,
    "name": "b_jawa",
    "field": "b_jawa"
  },
  {
    "label": "Prakarya",
    "align": "left",
    "sortable": false,
    "name": "prakarya",
    "field": "prakarya"
  },
  {
    "label": "Tik",
    "align": "left",
    "sortable": false,
    "name": "tik",
    "field": "tik"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tblogbayarlistheader: [
  {
    "label": "Id Logbayar",
    "align": "left",
    "sortable": false,
    "name": "id_logbayar",
    "field": "id_logbayar"
  },
  {
    "label": "Kd Tagihan",
    "align": "left",
    "sortable": false,
    "name": "kd_tagihan",
    "field": "kd_tagihan"
  },
  {
    "label": "Kd Siswa",
    "align": "left",
    "sortable": false,
    "name": "kd_siswa",
    "field": "kd_siswa"
  },
  {
    "label": "Tgl Bayar",
    "align": "left",
    "sortable": false,
    "name": "tgl_bayar",
    "field": "tgl_bayar"
  },
  {
    "label": "Buktibayar",
    "align": "left",
    "sortable": false,
    "name": "buktibayar",
    "field": "buktibayar"
  },
  {
    "label": "Log Bayar",
    "align": "left",
    "sortable": false,
    "name": "log_bayar",
    "field": "log_bayar"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbmutasiewaletlistheader: [
  {
    "label": "Id Trwallet",
    "align": "left",
    "sortable": false,
    "name": "id_trwallet",
    "field": "id_trwallet"
  },
  {
    "label": "Item Transaksi",
    "align": "left",
    "sortable": false,
    "name": "item_transaksi",
    "field": "item_transaksi"
  },
  {
    "label": "Tgl Transaksi",
    "align": "left",
    "sortable": false,
    "name": "tgl_transaksi",
    "field": "tgl_transaksi"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Akun No",
    "align": "left",
    "sortable": false,
    "name": "akun_no",
    "field": "akun_no"
  },
  {
    "label": "Log Trwallet",
    "align": "left",
    "sortable": false,
    "name": "log_trwallet",
    "field": "log_trwallet"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbnilaiharianlistheader: [
  {
    "label": "Id Nilharian",
    "align": "left",
    "sortable": false,
    "name": "id_nilharian",
    "field": "id_nilharian"
  },
  {
    "label": "Guru Id",
    "align": "left",
    "sortable": false,
    "name": "guru_id",
    "field": "guru_id"
  },
  {
    "label": "Kelas Id",
    "align": "left",
    "sortable": false,
    "name": "kelas_id",
    "field": "kelas_id"
  },
  {
    "label": "Siswa Id",
    "align": "left",
    "sortable": false,
    "name": "siswa_id",
    "field": "siswa_id"
  },
  {
    "label": "Matpel Id",
    "align": "left",
    "sortable": false,
    "name": "matpel_id",
    "field": "matpel_id"
  },
  {
    "label": "Nilaiharian",
    "align": "left",
    "sortable": false,
    "name": "nilaiharian",
    "field": "nilaiharian"
  },
  {
    "label": "Catatan",
    "align": "left",
    "sortable": false,
    "name": "catatan",
    "field": "catatan"
  },
  {
    "label": "Log Nharian",
    "align": "left",
    "sortable": false,
    "name": "log_nharian",
    "field": "log_nharian"
  },
  {
    "label": "Semester Id",
    "align": "left",
    "sortable": false,
    "name": "semester_id",
    "field": "semester_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbnilakhirlistheader: [
  {
    "label": "Kd Matpel",
    "align": "left",
    "sortable": false,
    "name": "kd_matpel",
    "field": "kd_matpel"
  },
  {
    "label": "Kd Semester",
    "align": "left",
    "sortable": false,
    "name": "kd_semester",
    "field": "kd_semester"
  },
  {
    "label": "Kd Siswa",
    "align": "left",
    "sortable": false,
    "name": "kd_siswa",
    "field": "kd_siswa"
  },
  {
    "label": "Nilai",
    "align": "left",
    "sortable": false,
    "name": "nilai",
    "field": "nilai"
  },
  {
    "label": "Catatan Nakhir",
    "align": "left",
    "sortable": false,
    "name": "catatan_nakhir",
    "field": "catatan_nakhir"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tborangtualistheader: [
  {
    "label": "Id Ortu",
    "align": "left",
    "sortable": false,
    "name": "id_ortu",
    "field": "id_ortu"
  },
  {
    "label": "N Ortu",
    "align": "left",
    "sortable": false,
    "name": "n_ortu",
    "field": "n_ortu"
  },
  {
    "label": "Siswa Id",
    "align": "left",
    "sortable": false,
    "name": "siswa_id",
    "field": "siswa_id"
  },
  {
    "label": "T Orangtua",
    "align": "left",
    "sortable": false,
    "name": "t_orangtua",
    "field": "t_orangtua"
  },
  {
    "label": "Agama",
    "align": "left",
    "sortable": false,
    "name": "agama",
    "field": "agama"
  },
  {
    "label": "Almt Ortu",
    "align": "left",
    "sortable": false,
    "name": "almt_ortu",
    "field": "almt_ortu"
  },
  {
    "label": "Tlp Ortu",
    "align": "left",
    "sortable": false,
    "name": "tlp_ortu",
    "field": "tlp_ortu"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Hub Keluarga",
    "align": "left",
    "sortable": false,
    "name": "hub_keluarga",
    "field": "hub_keluarga"
  },
  {
    "label": "Pendidikan",
    "align": "left",
    "sortable": false,
    "name": "pendidikan",
    "field": "pendidikan"
  },
  {
    "label": "Pekerjaan",
    "align": "left",
    "sortable": false,
    "name": "pekerjaan",
    "field": "pekerjaan"
  },
  {
    "label": "Gaji",
    "align": "left",
    "sortable": false,
    "name": "gaji",
    "field": "gaji"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	status: [    
{value: "Sakit", label: "Sakit"},
	{value: "Izin", label: "Izin"}
    ],
	tbsabsenlistheader: [
  {
    "label": "Id Sabs",
    "align": "left",
    "sortable": false,
    "name": "id_sabs",
    "field": "id_sabs"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Tgl Absen",
    "align": "left",
    "sortable": false,
    "name": "tgl_absen",
    "field": "tgl_absen"
  },
  {
    "label": "Siswa Id",
    "align": "left",
    "sortable": false,
    "name": "siswa_id",
    "field": "siswa_id"
  },
  {
    "label": "Apv Ortu",
    "align": "left",
    "sortable": false,
    "name": "apv_ortu",
    "field": "apv_ortu"
  },
  {
    "label": "Apv Walikelas",
    "align": "left",
    "sortable": false,
    "name": "apv_walikelas",
    "field": "apv_walikelas"
  },
  {
    "label": "Keterangan",
    "align": "left",
    "sortable": false,
    "name": "keterangan",
    "field": "keterangan"
  },
  {
    "label": "Log",
    "align": "left",
    "sortable": false,
    "name": "log",
    "field": "log"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbsiswalistheader: [
  {
    "label": "Id Siswa",
    "align": "left",
    "sortable": false,
    "name": "id_siswa",
    "field": "id_siswa"
  },
  {
    "label": "Nis",
    "align": "left",
    "sortable": false,
    "name": "nis",
    "field": "nis"
  },
  {
    "label": "Nisn",
    "align": "left",
    "sortable": false,
    "name": "nisn",
    "field": "nisn"
  },
  {
    "label": "N Lengkap",
    "align": "left",
    "sortable": false,
    "name": "n_lengkap",
    "field": "n_lengkap"
  },
  {
    "label": "N Pangilan",
    "align": "left",
    "sortable": false,
    "name": "n_pangilan",
    "field": "n_pangilan"
  },
  {
    "label": "J Kelamin",
    "align": "left",
    "sortable": false,
    "name": "j_kelamin",
    "field": "j_kelamin"
  },
  {
    "label": "Tmp Lahir",
    "align": "left",
    "sortable": false,
    "name": "tmp_lahir",
    "field": "tmp_lahir"
  },
  {
    "label": "Tgl Lahir",
    "align": "left",
    "sortable": false,
    "name": "tgl_lahir",
    "field": "tgl_lahir"
  },
  {
    "label": "Agama",
    "align": "left",
    "sortable": false,
    "name": "agama",
    "field": "agama"
  },
  {
    "label": "Alamat",
    "align": "left",
    "sortable": false,
    "name": "alamat",
    "field": "alamat"
  },
  {
    "label": "Kd Kelas",
    "align": "left",
    "sortable": false,
    "name": "kd_kelas",
    "field": "kd_kelas"
  },
  {
    "label": "Users Id",
    "align": "left",
    "sortable": false,
    "name": "users_id",
    "field": "users_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tbtabsiswalistheader: [
  {
    "label": "Id Tab",
    "align": "left",
    "sortable": false,
    "name": "id_tab",
    "field": "id_tab"
  },
  {
    "label": "Tgl Transaksi",
    "align": "left",
    "sortable": false,
    "name": "tgl_transaksi",
    "field": "tgl_transaksi"
  },
  {
    "label": "Ket Transaksi",
    "align": "left",
    "sortable": false,
    "name": "ket_transaksi",
    "field": "ket_transaksi"
  },
  {
    "label": "J Mutasi",
    "align": "left",
    "sortable": false,
    "name": "j_mutasi",
    "field": "j_mutasi"
  },
  {
    "label": "Nominal",
    "align": "left",
    "sortable": false,
    "name": "nominal",
    "field": "nominal"
  },
  {
    "label": "Saldo",
    "align": "left",
    "sortable": false,
    "name": "saldo",
    "field": "saldo"
  },
  {
    "label": "Siswa Id",
    "align": "left",
    "sortable": false,
    "name": "siswa_id",
    "field": "siswa_id"
  },
  {
    "label": "Log Transaksi",
    "align": "left",
    "sortable": false,
    "name": "log_transaksi",
    "field": "log_transaksi"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	jTugas: [    
{value: "Individu", label: "Individu"},
	{value: "Kelompok", label: "Kelompok"}
    ],
	tbtugaslistheader: [
  {
    "label": "Title Tugas",
    "align": "left",
    "sortable": false,
    "name": "title_tugas",
    "field": "title_tugas"
  },
  {
    "label": "Matpel Id",
    "align": "left",
    "sortable": false,
    "name": "matpel_id",
    "field": "matpel_id"
  },
  {
    "label": "Guru Id",
    "align": "left",
    "sortable": false,
    "name": "guru_id",
    "field": "guru_id"
  },
  {
    "label": "Mulai Tugas",
    "align": "left",
    "sortable": false,
    "name": "mulai_tugas",
    "field": "mulai_tugas"
  },
  {
    "label": "Akhir Tugas",
    "align": "left",
    "sortable": false,
    "name": "akhir_tugas",
    "field": "akhir_tugas"
  },
  {
    "label": "Sumber Tugas",
    "align": "left",
    "sortable": false,
    "name": "sumber_tugas",
    "field": "sumber_tugas"
  },
  {
    "label": "Kelas Id",
    "align": "left",
    "sortable": false,
    "name": "kelas_id",
    "field": "kelas_id"
  },
  {
    "label": "Log Tugas",
    "align": "left",
    "sortable": false,
    "name": "log_tugas",
    "field": "log_tugas"
  },
  {
    "label": "J Tugas",
    "align": "left",
    "sortable": false,
    "name": "j_tugas",
    "field": "j_tugas"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	tindividulistheader: [
  {
    "label": "Title Tugas",
    "align": "left",
    "sortable": false,
    "name": "title_tugas",
    "field": "title_tugas"
  },
  {
    "label": "Mata Pelajaran",
    "align": "left",
    "sortable": false,
    "name": "matpel",
    "field": "matpel"
  },
  {
    "label": "Mulai ",
    "align": "left",
    "sortable": false,
    "name": "mulai_tugas",
    "field": "mulai_tugas"
  },
  {
    "label": "Selesai",
    "align": "left",
    "sortable": false,
    "name": "akhir_tugas",
    "field": "akhir_tugas"
  },
  {
    "label": "Jenis Tugas",
    "align": "left",
    "sortable": false,
    "name": "j_tugas",
    "field": "j_tugas"
  }
],
	tranewalletlistheader: [
  {
    "label": "Item Transaksi",
    "align": "left",
    "sortable": false,
    "name": "item_transaksi",
    "field": "item_transaksi"
  },
  {
    "label": "Tanggal Transaksi",
    "align": "left",
    "sortable": false,
    "name": "tgl_transaksi",
    "field": "tgl_transaksi"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  }
],
	userslistheader: [
  {
    "label": "Id User",
    "align": "left",
    "sortable": false,
    "name": "id_user",
    "field": "id_user"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Fullname",
    "align": "left",
    "sortable": false,
    "name": "fullname",
    "field": "fullname"
  },
  {
    "label": "Nisn",
    "align": "left",
    "sortable": false,
    "name": "nisn",
    "field": "nisn"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Profil Pic",
    "align": "left",
    "sortable": false,
    "name": "profil_pic",
    "field": "profil_pic"
  },
  {
    "label": "Log",
    "align": "left",
    "sortable": false,
    "name": "log",
    "field": "log"
  },
  {
    "label": "User Role Id",
    "align": "left",
    "sortable": false,
    "name": "user_role_id",
    "field": "user_role_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	vabsensiswalistheader: [
  {
    "label": "Id Sabs",
    "align": "left",
    "sortable": false,
    "name": "id_sabs",
    "field": "id_sabs"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Tgl Absen",
    "align": "left",
    "sortable": false,
    "name": "tgl_absen",
    "field": "tgl_absen"
  },
  {
    "label": "Siswa Id",
    "align": "left",
    "sortable": false,
    "name": "siswa_id",
    "field": "siswa_id"
  },
  {
    "label": "Apv Ortu",
    "align": "left",
    "sortable": false,
    "name": "apv_ortu",
    "field": "apv_ortu"
  },
  {
    "label": "Apv Walikelas",
    "align": "left",
    "sortable": false,
    "name": "apv_walikelas",
    "field": "apv_walikelas"
  },
  {
    "label": "Keterangan",
    "align": "left",
    "sortable": false,
    "name": "keterangan",
    "field": "keterangan"
  },
  {
    "label": "Log",
    "align": "left",
    "sortable": false,
    "name": "log",
    "field": "log"
  },
  {
    "label": "Color",
    "align": "left",
    "sortable": false,
    "name": "color",
    "field": "color"
  }
],
	vkaldiklistheader: [
  {
    "label": "Id Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "id_kegiatan",
    "field": "id_kegiatan"
  },
  {
    "label": "Title Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "title_kegiatan",
    "field": "title_kegiatan"
  },
  {
    "label": "Tgl Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "tgl_kegiatan",
    "field": "tgl_kegiatan"
  },
  {
    "label": "Pic Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "pic_kegiatan",
    "field": "pic_kegiatan"
  },
  {
    "label": "Time Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "time_kegiatan",
    "field": "time_kegiatan"
  }
],
	vtabsiswalistheader: [
  {
    "label": "Tanggal Transaksi",
    "align": "left",
    "sortable": false,
    "name": "tgl_transaksi",
    "field": "tgl_transaksi"
  },
  {
    "label": "Keterangan Transaksi",
    "align": "left",
    "sortable": false,
    "name": "ket_transaksi",
    "field": "ket_transaksi"
  },
  {
    "label": "Mutasi",
    "align": "left",
    "sortable": false,
    "name": "j_mutasi",
    "field": "j_mutasi"
  },
  {
    "label": "Nominal",
    "align": "left",
    "sortable": false,
    "name": "nominal",
    "field": "nominal"
  },
  {
    "label": "Saldo",
    "align": "left",
    "sortable": false,
    "name": "saldo",
    "field": "saldo"
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}